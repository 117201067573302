.link:hover {
  background-color: var(--blue-100);
}

.site-selector .react-window-item + .react-window-item {
  border-top: 1px solid var(--gray-200);
}

.site-select-item {
  white-space: inherit;
}
.site-select-item.active,
.site-select-item:active {
  background-color: var(--blue-400);
}
.site-select-item a:hover {
  text-decoration: none;
}

.site-group {
  width: 80%;
  white-space: normal;
}

.switcher-width {
  width: 500px;
}

@media screen and (max-width: 600px) {
  .switcher-width {
    width: 280px;
  }
}
@media screen and (min-width: 600px) {
  .switcher-width {
    width: 500px;
  }
}
.name {
  color: var(--primary-dark-gray);
  font-size: 15px;
  margin-bottom: 0;
}
.site-select-item.active .name,
.site-select-item:active .name {
  color: #fff;
}

.url {
  color: var(--primary-light-gray);
  font-size: 12px;
  margin-bottom: 0;
}
.site-select-item.active .url,
.site-select-item:active .url {
  color: var(--blue-200);
}
