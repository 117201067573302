.wrapper {
  border-radius: 4px;
  background-color: white;
  color: #374046;
  border: solid 1px #d2dcee;
  width: 100%;
  padding: 10px;
  cursor: pointer;
}

.children {
  width: 84%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
