.wrapper {
  width: 245px;
  box-sizing: border-box;
}

.toggle {
  background-color: white;
  color: #374046;
  border-color: #d2dcee;
}

.toggle:hover {
  background-color: white;
  color: #374046;
  border-color: #d2dcee;
}

.close {
  display: flex;
  align-items: center;
  color: #374046;
  cursor: pointer;

  position: absolute;
  top: 12px;
  right: 36px;
  width: 20px;
  height: 20px;
  line-height: 22px;
  padding: 0;
  border: 0;
  border-radius: 2px;
  margin-left: 10px;
  background: none;
  -webkit-appearance: none;
}

.close:hover {
  background-color: var(--gray-100);
}

.menuOuter {
  width: 400px;
  right: 0;
}

.menuWrapper {
  margin-top: 16px;
  width: 100%;
}

.menuWrapper:after,
.menuWrapper:before {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  content: '';
  display: inline-block;
}

.menuWrapper:before {
  top: -11px;
  right: 17px;
  border-width: 0px 10px 11px 10px;
  border-color: transparent transparent #dce1e4 transparent;
  z-index: 2;
}

.menuWrapper:after {
  top: -9px;
  right: 18px;
  border-width: 0 9px 9px 9px;
  border-color: transparent transparent #fff transparent;
  z-index: 3;
}
